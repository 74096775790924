
import '~/assets/css/students.scss'

export default {
  name: 'Student-Site',
  props: [ 'dict',
           'VFAParameters',
           'buttonBackground'
   ],
  computed: {
    isStudentSite () {
      return true
    },
    sentence1 () {
      /**
       * 2023-11-27 John Yee
       * on the Student site...
       * sentence1 is the sentence "You're a US citizen..."
       * 
       * insertion_character is the character we use to point to the location
       * where "and student" is supposed to be inserted in the normal text
       */
      const insertion_character = this.isStudentSite ? "^" : " "
      return this.dict.P22.replace('<span class="insertchar"></span>', '<span class="insertchar">'+insertion_character+'</span>')
    },
    new_voter_button_image () {
      return this.newVoterButtonParameters.background_image
    },
    new_voter_FAQ_URL () {
      return this.$i18n.locale==='enUS' ? '/faqs/new-voters' : '/'+this.$i18n.locale+'/faqs/new-voters'
    },
    newVoterButtonParameters () {
      let background_image = null
      let background_color = "rgb(255,255,255)"
      let animation_duration = 0

      background_image = this.buttonBackground
      if (this.VFAParameters) {
        const bg = this.VFAParameters.LANDING_PAGE_NEW_VOTER_BUTTON_BACKGROUND_COLOR.replaceAll(" ","").split(",")
        background_color = "rgb("+bg[0]+","+bg[1]+","+bg[2]+")"

        const rootElement = document.documentElement
        const animation_Duration_Factor = getComputedStyle(rootElement,null).getPropertyValue('--animation-duration-factor')
        const LANDING_PAGE_NEW_VOTER_BUTTON_ANIMATION_DURATION = animation_Duration_Factor * this.VFAParameters.LANDING_PAGE_NEW_VOTER_BUTTON_ANIMATION_DURATION
        animation_duration = LANDING_PAGE_NEW_VOTER_BUTTON_ANIMATION_DURATION.toString()+"s"
      }

      return {
        background_image,
        background_color,
        animation_duration
      }
    },
  }
}
